import { ApiResponseBodyType, client, CreatedResponseBody } from '../../client';
import { CsvExportPromotionHistoryList } from '../../../types/csvExportPromotionHistory';
import { apiURLPrefix } from '../urls';

export const getCsvExportPromotionHistories = async (
  page: number
): Promise<ApiResponseBodyType<CsvExportPromotionHistoryList>> =>
  client.get<CsvExportPromotionHistoryList>(
    `${apiURLPrefix}/csv_export_promotion_usable_master/histories?page=${page}`
  );

export const postCsvExportPromotionHistory = async (
  csvExportPromotionRoomPropertyIds: ReadonlyArray<number>,
  csvExportPromotionUserIds: ReadonlyArray<number>
): Promise<ApiResponseBodyType<CreatedResponseBody>> =>
  client.post(`${apiURLPrefix}/csv_export_promotion_usable_master/histories`, {
    room_property_ids: csvExportPromotionRoomPropertyIds,
    csv_export_promotion_user_ids: csvExportPromotionUserIds
  });

export const maxSelectableCsvExportPromotionUserCount = 600;
export const maxSelectableCsvExportPromotionRoomCount = 10;

export const isRequestablePostCsvExportPromotionBrokerageCompanyListUserIdsLength =
  (userIdsLength: number): boolean =>
    userIdsLength <= maxSelectableCsvExportPromotionUserCount;
